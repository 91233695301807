import { FinishNewsletterPopupProps } from '@husky-x/cc-finish-newsletter-popup/dist/types/models';

export const FinishProductDifferencesMock = {
  header: '<h2>ERFAHREN SIE WIE SICH UNSERE PRODUKTE UNTERSCHEIDEN</h2>',
  borderedImage: true,
  navigationButton: {
    link: '/produkte/',
    label: 'ALLE PRODUKTE ANSEHEN',
  },
  useCheckmark: false,
  tables: [
    {
      title: 'FINISH',
      legend:
        '<ul>\n<li><sup>*</sup>Bei stark verschmutztem Geschirr</li>\n<li><sup>**</sup>vs. Finish Power basierend auf dem Durchschnittsgewicht</li>\n<li><sup>***</sup>Inhaltsstoff: Enzym</li>\n</ul>',
      rows: [
        {
          text: 'Reinigung',
          image:
            'https://eu-images.contentstack.com/v3/assets/blt639569eaa143b45b/blt82888abe65a3cf92/66a8bd75f24439cde173be08/clean.svg',
        },
        {
          text: 'Gland',
          image:
            'https://eu-images.contentstack.com/v3/assets/blt639569eaa143b45b/blt4068f74db0e98470/66a8bdf6b8115e2482a37a72/shine.svg',
        },
        {
          text: 'Entfernt 48h Eingetrocknetes<sup>*</sup>',
          image:
            'https://eu-images.contentstack.com/v3/assets/blt639569eaa143b45b/blt4b1a83960fae2f42/66a8bd75b8115e47cca37a6d/dried-on.svg',
        },
        {
          text: 'Fettlösekraft',
          image:
            'https://eu-images.contentstack.com/v3/assets/blt639569eaa143b45b/bltef4110afe98d3440/66a8bdf6f24439951073be0f/tough-on-grease.svg',
        },
        {
          text: 'Kein Vorspülen',
          image:
            'https://eu-images.contentstack.com/v3/assets/blt639569eaa143b45b/bltb35fc648c6362147/66a8bdf61097ec6bcaa86bf5/skip-the-rinse.svg',
        },
        {
          text: 'Ohne Auspacken',
          image:
            'https://eu-images.contentstack.com/v3/assets/blt639569eaa143b45b/blt906d9e1af7cf7cf8/66a8bd7575e8676dacd73cd9/wrapper-free-tabs.svg',
        },
        {
          text: 'Schnellauflösend',
          image:
            'https://eu-images.contentstack.com/v3/assets/blt639569eaa143b45b/blt84a3d1465f181c87/66a8bdf67da9b43d0cb481c0/fast-dissolving.svg',
        },
        {
          text: 'Weniger Chemikaliengehal<sup>**</sup>',
          image:
            'https://eu-images.contentstack.com/v3/assets/blt639569eaa143b45b/blt0b422bd29369e3e4/66a8bdf6739577514719fa1a/less-chemical-weight.svg',
        },
        {
          text: 'Glasschutz',
          image:
            'https://eu-images.contentstack.com/v3/assets/blt639569eaa143b45b/blta20864530554004a/66a8bd751a892d24844a3ac8/glass-protection.svg',
        },
        {
          text: 'Verleiht mehr Frische',
          image:
            'https://eu-images.contentstack.com/v3/assets/blt639569eaa143b45b/blt95a23ce6c566f108/66a8bd7517cd4f0b596f724a/freshness-boost.svg',
        },
        {
          text: 'Entfernt Eingebranntes effektiv',
          image:
            'https://eu-images.contentstack.com/v3/assets/blt639569eaa143b45b/bltf4e3970c7de0d7ea/66a8bd7506435dc50cddea0b/burnt-on-stains.svg',
        },
        {
          text: 'Richtiger Inhaltsstoff<sup>***</sup> zur richtigen Zeit',
          image:
            'https://eu-images.contentstack.com/v3/assets/blt639569eaa143b45b/blt4228905f1347b8d8/66a8bd75f095557978747345/right-ingredient-right-time-1.png',
        },
      ],
      columns: [
        {
          productImage:
            'https://eu-images.contentstack.com/v3/assets/blt639569eaa143b45b/blt5a1d9e23a9f38b42/66a8bab8f2443983a573bdfc/ultimate-plus-x2.png',
          productName: 'ULTIMATE PLUS',
          productLink: '/produkte/reiniger/ultimate-plus-spuelmaschinentabs/48-regular/',
          color: 'gold-gradient',
          values: [
            {
              value: 5,
            },
            {
              value: 4,
            },
            {
              value: 4,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
          ],
        },
        {
          productImage:
            'https://eu-images.contentstack.com/v3/assets/blt639569eaa143b45b/blt885b71b3dca45c5a/66a8bd76b3baaf4f6397e363/ultimate-x2.png',
          productName: 'ULTIMATE',
          productLink: '/produkte/reiniger/ultimate-spulmaschinentabs/54-regular/',
          color: 'black-gradient',
          values: [
            {
              value: 4,
            },
            {
              value: 4,
            },
            {
              value: 3,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
          ],
        },
        {
          productImage:
            'https://eu-images.contentstack.com/v3/assets/blt639569eaa143b45b/blt9a8d029b63113ce2/66a8bd75fb2f0f64f438eba8/quantum-x2.png',
          productName: 'QUANTUM',
          productLink: '/produkte/reiniger/quantum-spuelmaschinentabs/58-regular/',
          color: 'dark-blue-gradient',
          values: [
            {
              value: 3,
            },
            {
              value: 3,
            },
            {
              value: 2,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
          ],
        },
        {
          productImage:
            'https://eu-images.contentstack.com/v3/assets/blt639569eaa143b45b/bltf7e1f373bd749614/66a8bd753d47313511294ec4/power-x2.png',
          productName: 'POWER',
          productLink: '/produkte/reiniger/power-spuelmaschinentabs/30-regular/',
          color: 'blue-gradient',
          values: [
            {
              value: 2,
            },
            {
              value: 2,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
          ],
        },
      ],
    },
  ],
};

export const FinishProductCardsMock = {
  cards: [
    {
      productName: 'FINISH ULTIMATE',
      image:
        'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blte286e6fce14d0ead/6758621107400ba4391ac9c9/Finish_Ultimate_PT.webp',
      cardStyle: 'dark-blue',
      button: {
        link: '/produtos/detergentes/pastilhas/finish-ultimate/48-regular/',
        text: 'SAIBA MAIS',
      },
      icons: [
        {
          src: 'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blte8b7fc4192597cca/67586211496c00905d35481e/Gordura_Incrustrada_3.webp',
          name: 'GORDURA INCRUSTRADA',
        },
        {
          src: 'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt78275dbefb415745/6758621109c3e23527887ed0/Machas_Ressequidas_De_Ate_48h_2.webp',
          name: 'MANCHAS RESSEQUIDAS DE ATÉ 48H',
        },
      ],
      mobileOrder: 2,
    },
    {
      productName: 'FINISH ULTIMATE PLUS',
      primary: true,
      image:
        'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/bltaf9cdaba4be85add/675862118a5af463e9e2c715/Finish_Ultimate_Plus_PT.webp',
      cardStyle: 'black',
      button: {
        link: '/produtos/detergentes/pastilhas/ultimateplus/regular/',
        text: 'SAIBA MAIS',
      },
      icons: [
        {
          src: 'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blta9a61c46cfb94b70/67586211098d3949c524131a/3x_Extra_Poder_De_Limpeza_1.webp',
          name: '3X EXTRA PODER DE LIMPEZA',
        },
        {
          src: 'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/bltfa1dc0cca409eaf6/67586211e2ba1d4f5cf74882/Gordura_Incrustrada_1.webp',
          name: 'GORDURA INCRUSTRADA',
        },
        {
          src: 'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt1dce797f86ad9dda/675862111f4caba7698e2234/Restos_Quiemados_De_Comida_1.webp',
          name: 'RESTOS QUEIMADOS DE COMIDA',
        },
      ],
      mobileOrder: 1,
    },
    {
      productName: 'FINISH QUANTUM',
      image:
        'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt59580a85c769baea/67586211665ec024d35210fb/Finish_Quantum_PT.webp',
      cardStyle: 'light-blue',
      button: {
        link: '/produtos/detergentes/pastilhas/quantum-max/72-regular/',
        text: 'SAIBA MAIS',
      },
      icons: [
        {
          src: 'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blte8b7fc4192597cca/67586211496c00905d35481e/Gordura_Incrustrada_3.webp',
          name: 'GORDURA INCRUSTRADA',
        },
      ],
      mobileOrder: 3,
    },
  ],
};

export const FinishNewsletterPopupMock: FinishNewsletterPopupProps = {
  headline: 'NICHTS MEHR VERPASSEN',
  image: {
    src: 'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt7512496341f5a468/66f53c689441d6ee01fe1a7d/newsletter_popup_image.png',
    mobileSrc:
      'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt63c4941b86e10813/66f53c833097ebb254ddd0d8/newsletter_popup_image_mobile.png',
    alt: 'finish',
  },
  thankYou: {
    headline: 'VIELEN DANK FÜR IHRE ANMELDUNG FÜR DEN FINISH NEWSLETTER!',
    description: '',
    closeButton: {
      label: 'schließen',
      redirectUrl: '/',
    },
  },
  form: {
    id: '7f4311c4-00e3-48f9-a9cb-13b20ef2b28f',
    url: 'https://phx-promoplatform-api-pl-prod.promoplatform.rbcloud.io/api/v1/external/forms',
    submitErrorMessage: 'Bitte füllen Sie die erforderlichen Felder aus.',
  },
};
